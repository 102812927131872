<template>
  <div class="me" :style="controlStyle" @click.stop.prevent="onClick">
    <!-- table table-condensed table-bordered table-hover dataTable table-striped table-responsive -->
    <template v-for="(page, ip) in pages">
      <!-- <h1 class="page-break" :key="`nextpage${ip}`">page</h1> -->
      <table
        :id="tableId"
        ref="table"
        class="table display-table"
        :key="`page${ip}`"
        :style="[styleCfg.cssVars, { 'margin-bottom': `${page.spacing}px` }]"
        :class="{
          ...styleCfg.classes,
          ...{
            'print-preview': printPreview
          }
        }"
        @mousedown.exact="onMouseDown($event)"
        @dragenter.stop.prevent
        @dragover.stop.prevent
      >
        <tbody ref="sheet" @dragover="dragOverColumn($event)">
          <tr v-for="(row, ir) in page.rows" :key="ir">
            <td
              v-for="(col, ic) in row"
              :key="ic"
              :style="cellStyle(page.offset + ir, ic)"
              :class="cellClass(page.offset + ir, ic)"
              @click.stop.prevent="onCellClicked(page.offset + ir, ic)"
              @contextmenu.stop.prevent="
                onCellMenuClicked($event, page.offset + ir, ic, null)
              "
            >
              <div
                tabindex="0"
                class="cell"
                :style="cellContentStyle(page.offset + ir, ic)"
                @keyup="onKeyUp($event)"
              >
                <CellInput
                  v-if="allowInput(page.offset + ir, ic)"
                  :value="values[normalizeRow(ir, page)][ic]"
                  @input="setCellValue(page.offset + ir, ic, $event)"
                  @contextmenu.stop.prevent="
                    onCellMenuClicked($event, page.offset + ir, ic, null)
                  "
                  @keyup.delete.stop.prevent="onKeyUp"
                  @click.stop.prevent="onCellClicked(page.offset + ir, ic)"
                />
                <span
                  v-else-if="inlineEditor"
                  @contextmenu.stop.prevent="
                    onCellMenuClicked($event, page.offset + ir, ic, null)
                  "
                  @click.stop.prevent="onCellClicked(page.offset + ir, ic)"
                  >{{ values[normalizeRow(ir, page)][ic] }}&nbsp;
                </span>
                <span v-else>
                  {{ values[normalizeRow(ir, page)][ic] }}&nbsp;
                </span>
              </div>
              <div
                draggable="true"
                class="cell-separator"
                :class="{
                  'column-dragging': dragging.c == ic
                }"
                :style="{
                  right: `${-1 * (dragging.c == ic ? dragging.w : 0)}px`
                }"
                v-if="isEditing && ic < row.length - 1"
                @mousedown.exact="onMouseDownDragColumn($event, ir, ic)"
                @mouseup.exact="onMouseUpDragColumn($event, ir, ic)"
                @dragstart="startDragColumn($event, ir, ic)"
                @dragend="stopDragColumn($event)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div style="page-break-before: always;" :key="`nextpage${ip}`"></div> -->
    </template>
    <portal to="contextmenu" v-if="isEditing">
      <vue-context
        ref="menu"
        class="contextmenu"
        v-if="isEditing"
        @open="onContextMenu()"
        :closeOnScroll="false"
        :useScrollHeight="false"
        :heightOffset="menu.oy"
      >
        <!-- :closeOnScroll="cmCloseOnScroll" -->
        <template>
          <li
            class="contextmenu-info address"
            @click.stop.prevent="
              onCellMenuClicked($event, null, null, 'cell:edit')
            "
          >
            {{ a1 }} <i class="fa fa-pencil"></i>
          </li>
          <li
            class="contextmenu-info close"
            @click.stop.prevent="$refs.menu.close()"
          >
            <i class="fa fa-close"></i>
          </li>
          <li class="gap"></li>
          <template v-for="(menuItem, io) in cellMenu">
            <li v-if="menuItem.title == '-'" class="divider" :key="io"></li>
            <li v-else :key="io">
              <a
                class="has-shortcut"
                @click.stop.prevent="
                  onCellMenuClicked($event, null, null, menuItem.option)
                "
              >
                {{ $t(menuItem.title) }}
              </a>
            </li>
          </template>
        </template>
      </vue-context>
    </portal>
    <div style="position: relative; display: block" v-if="isEditing">
      <!-- {{ dataset }} -->
    </div>
  </div>
</template>

<script>
import VueContext from "@/plugins/vue-context";
import CellInput from "@/components/widgets/cell-input.vue";
import { defCell } from "@/components/control-sidebar/property-editors/detail-form-table.vue";
import { currentValueTypeCast } from "@/services/equipment-data.js";
import { fillList, linearInterpolation } from "@/modules/history.js";
import { uniqBy, sortBy } from "lodash";
export default {
  name: "SynopticSimpleTable",
  props: {
    control: {
      type: [Array, Object],
      required: false,
      default: () => null
    },
    inlineEditor: {
      type: Boolean,
      default: true,
      required: false
    },
    standAlone: {
      // true - it triggers edit events by itself / false - the parent component does.
      type: Boolean,
      default: true,
      required: false
    },
    offsetTop: {
      type: Number,
      required: false,
      default: null
    },
    currentRect: {
      type: Object,
      required: false,
      default: () => null
    },
    datasetIdList: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  inject: {
    pageSettings: {
      from: "pageSettings",
      default: null
    }
  },
  components: {
    CellInput,
    VueContext
  },
  data() {
    return {
      active: { r: -1, c: -1 },
      cellMenu: [
        { title: "rich_text.add_row_before", option: "row:add_above" },
        { title: "rich_text.add_row_after", option: "row:add_below" },
        { title: "rich_text.delete_row", option: "row:del" },
        { title: "rich_text.reset_row_style", option: "row:reset_style" },
        { title: "rich_text.reset_row_content", option: "row:reset_content" },
        { title: "-" },
        { title: "rich_text.add_column_before", option: "column:add_left" },
        { title: "rich_text.add_column_after", option: "column:add_right" },
        { title: "rich_text.delete_column", option: "column:del" },
        { title: "rich_text.reset_column", option: "column:reset" },
        { title: "-" },
        { title: "rich_text.reset_cell", option: "cell:reset" }
      ],
      cellInput: false,
      errorCheck: 0,
      menu: { sx: 0, sy: 0, oy: 0 },
      rowHeight: null,
      tableId: "",
      dragging: { r: -1, c: -1, w: -1 },
      datasetReady: false
    };
  },
  computed: {
    printScale() {
      return this?.pageSettings?.scale ?? 1;
    },
    pageHeight() {
      return (
        (this?.pageSettings?.orientation == "p"
          ? this?.pageSettings?.height
          : this?.pageSettings?.width) ?? 0
      );
    },
    pagePadding() {
      return this?.pageSettings?.padding ?? 0;
    },
    values() {
      // output sheet (already formatted cells)
      let entry = null, vlr = "";
      return (this?.labeledSheet?.rows || []).map((r, ir) =>
        r.map((c, ic) => {
          entry = this.dataAt(ir, ic);
          vlr = entry
            ? this.$root.$formatter.format(entry)
            : this.value(ir, ic);
          return vlr === "" && entry && entry.default ? entry.default : vlr;
        })
      );
    },
    sheet() {
      // Control property:
      // - While legacy Synoptic control (messer), it requires a dataSheet attribute
      // - While Tablepanel control is meant to be the sheet configuration (new)

      // const ret = this?.dataset?.length
      //   ? this.values
      //   : this?.control?.dataSheet ||
      //     this?.control?.synopticComponent?.sheet || [[{ value: "" }]];

      return this.datasetReady
        ? this._values
        : this?.control?.dataSheet ||
        this?.control?.synopticComponent?.sheet || [[{ value: "" }]];
    },
    labeledSheet() {
      if (!this.pageHeight || !this._isMounted || !this.datasetReady)
        return { rows: this.sheet, offset: [0] };
      let pageHeight = this.pageHeight - this.pagePadding;
      let heightInFirstPage = pageHeight - this.offsetTop;

      let nItemsInFirstPage =
        (this.$attrs.nItemsInFirstPage ?? 0) ||
        Math.floor(heightInFirstPage / this.rowHeight) - 1;

      let nItemsInRemainingPages =
        (this.$attrs.nItemsInRemainingPages ?? 0) ||
        Math.floor(pageHeight / this.rowHeight) - 1;

      nItemsInFirstPage = nItemsInFirstPage < 2 ? 2 : nItemsInFirstPage;
      nItemsInRemainingPages =
        nItemsInRemainingPages < 2 ? 2 : nItemsInRemainingPages;

      let offset = [0];
      let rows = this.sheet.map((r) => [...r]);
      // get the custom rows used as header that preceeds the dataset target
      let headerRows = [...rows.slice(0, this.datasetTarget?.r ?? 0)];
      // inserts header for second page
      let index = nItemsInFirstPage;
      if (rows.length > index) {
        rows.splice(index, 0, ...headerRows);
        offset.push(index);
      }
      // inserts header for other pages (from the third onwards)
      for (
        index = nItemsInFirstPage + nItemsInRemainingPages;
        index < rows.length;
        index += nItemsInRemainingPages - headerRows.length + 1
      ) {
        offset.push(index);
        rows.splice(index, 0, ...headerRows);
      }
      return { rows: rows, offset: offset };
    },
    pages() {
      if (!(this.sheet || []).length)
        return [
          {
            offset: 0,
            rows: []
          }
        ];
      if (!this.rowHeight || !this.datasetReady)
        return [
          {
            offset: 0,
            rows: this.labeledSheet.rows
          }
        ];

      let pageHeight = this.pageHeight - this.pagePadding;
      let pages = [];
      let offsetTop = (this.offsetTop || 0) - 5;
      let rows = [];
      for (var i = 0; i < this.labeledSheet.offset.length; i++) {
        rows = this.labeledSheet.rows.slice(
          this.labeledSheet.offset[i],
          this.labeledSheet.offset[i + 1]
        );
        let spacing = 0;
        if (
          this.labeledSheet.offset.length > 1 &&
          i != this.labeledSheet.offset.length - 1
        ) {
          spacing =
            (pageHeight - offsetTop - this.rowHeight * rows.length) /
            this.printScale;
        }
        pages.push({
          spacing: Math.floor(spacing),
          offset: this.labeledSheet.offset[i],
          rows: rows
        });
        offsetTop = -5;
      }
      return pages;
    },
    fields() {
      var self = this;
      var sheet = self.sheet;
      var ret = sheet.map(function(row) {
        return {
          data_id: row[1].data_id,
          decimals: 0,
          default: 0,
          format: row[1].format,
          label: row[0].value,
          refreshInterval: 0,
          selected: true
        };
      });
      return ret;
    },
    selected() {
      var self = this;
      var sheet = self.sheet;
      var lst = sheet.map(function(r) {
        return r[1].data_id;
      });
      return lst;
    },
    nColumns() {
      let max = 0;
      if (this.sheet) {
        this.sheet.forEach((row) => {
          max = row.length > max ? row.length : max;
        });
      }
      return max;
    },
    nRows() {
      return this?.sheet?.length || 0;
    },
    size() {
      return { rows: this.nRows, cols: this.nColumns };
    },
    tmp() {
      // temporary actions result (not persistent)
      return this?.control?.synopticComponent?.tmp || null;
    },
    controlStyle() {
      let style = {
        ...(this?.control?.synopticComponent?.style || {}),
        transform: `rotate(${parseInt(
          this?.control?.synopticComponent?.rotation || 0
        )}deg)`
      };
      if (this.currentRect) {
        style.width = this.currentRect.width + "px";
        style.height = this.currentRect.height + "px";
      }
      if (this.isEditing) {
        style["z-index"] = "9";
      }
      if (this.errorCheck && this.rectError()) {
        style["overflow"] = "hidden";
      }
      if (this.tmp && this.tmp.style) {
        Object.assign(style, this.tmp.style);
      }
      return style;
    },
    tableStyle() {
      let entry = JSON.parse(
        JSON.stringify(this?.control?.synopticComponent?.tableStyle || null)
      );
      if (entry) {
        if (!entry.classes["table-bordered"]) {
          entry.cssVars["--border-width"] = 0;
        }
        if (this.errorCheck && this.rectError()) {
          entry.classes["table-error"] = true;
        }
        entry.cssVars["--text-size-adjust"] = this.$utils.iOS() ? "35%" : "70%";
      }

      return entry;
    },
    styleCfg() {
      let style = this.tableStyle || {
        classes: {
          "table-bordered": true,
          "table-condensed": true,
          "table-striped": true,
          "table-hover": true
        },
        cssVars: {
          "--border-width": "1px", // must be 0 if no bordered
          "--border-style": "solid",
          "--border-color": "gray",
          "--text-size-adjust": this.$utils.iOS() ? "35%" : "70%"
        }
      };
      return style;
    },
    history() {
      let entries = null;
      if (this.datasetIdList.length) {
        let history = this.$store.getters["history/entries"] || {};
        this.datasetIdList.forEach((id) => {
          if (id in history) {
            entries = entries || {};
            entries[id] = history[id];
          }
        });
      }
      return entries;
    },
    datasetTarget() {
      // convert A1 notation to {c:0, r:0} (target cell)
      let target = null;
      if (this?.control?.synopticComponent?.sheet) {
        let cfg = this?.control?.synopticComponent?.dataSetConfig || {};
        if (cfg?.address) {
          let c = parseInt(cfg.address.charCodeAt(0) - 65);
          let r = parseInt(cfg.address.replace(/\D/g, "")) - 1;
          if (
            r >= 0 &&
            r <= this?.control?.synopticComponent?.sheet.length - 1 &&
            c >= 0 &&
            c <= this?.control?.synopticComponent?.sheet[0].length - 1
          ) {
            target = { r: r, c: c };
          }
        }
      }
      return target;
    },
    dataset() {
      if ((this.customDataset || []).length) return this.customDataset;
      const dataIds = this.datasetIdList;
      const history = this.history;
      if (!history || !dataIds.length) return null;
      // figure out the data with larger number of samples
      let canInfer = true;
      let parsers = {};
      this.dataList.forEach(({ id, type }) => {
        parsers[id] =
          type == "string" ? null : type == "bool" ? parseInt : parseFloat;
        if (canInfer && (type == "string" || type == "bool")) {
          canInfer = false;
        }
      });
      // possible injected columns [seq, time, data1-n...]
      let missingValues =
        (canInfer &&
          (this?.control?.synopticComponent?.dataSetConfig || {})
            .missingValues) ||
        "last_value";

      let sequenceColumn = (
        this?.control?.synopticComponent?.dataSetConfig || {}
      ).sequenceColumn
        ? true
        : false;
      let timeColumn = (this?.control?.synopticComponent?.dataSetConfig || {})
        .timeColumn
        ? true
        : false;
      let cols = (sequenceColumn && timeColumn
        ? ["seq", "timestamp"] // sequence and time
        : sequenceColumn || timeColumn
          ? ["timestamp"] // sequence or time
          : []
      ).concat(dataIds);

      // reference column
      let refDataId = null;
      let refCol = null;
      // IMPORTANT
      // this configuration is not yet available for user configuration (panel form)
      // In order to have compatible results with HistoryPanel, the new "leave_them_empty" value
      // has been introduced. Right now it forces cfgRefCol type to "None"

      let cfgRefCol = (this?.control?.synopticComponent?.dataSetConfig || {})?.refColumn || {
        type: "auto",
        data_id: null
      }
      // TODO: as stated by previous comment
      // remove line below as soon reference column be available for user configuration
      if (missingValues == "leave_them_empty") {
        cfgRefCol.type = "none";
      }

      // build reference column
      if (cfgRefCol.type == "auto") {
        // refDataId = (dataIds || []).sort((a, b) => {
        //   var ca = a in history ? history[a]?.stats?.count || 0 : 0;
        //   var cb = b in history ? history[b]?.stats?.count || 0 : 0;
        //   return ca > cb ? 1 : cb > ca ? -1 : 0;
        // })[0];
        refDataId = (dataIds || []).reduce((a, b) => {
          var ca = a in history ? history[a]?.stats?.count || 0 : 0;
          var cb = b in history ? history[b]?.stats?.count || 0 : 0;
          return !ca && !cb ? -1 : ca > cb ? a : b;
        });
        refDataId = refDataId == -1 ? dataIds[0] : refDataId;
        refCol = history[refDataId]?.samples;
      } else if (cfgRefCol.type == "none") {
        refCol = [];
        for (var dataId in history) {
          refCol = refCol.concat(
            (history[dataId]?.samples || []).map(({ time, date_time }) => ({
              time: time,
              date_time: date_time
            }))
          );
        }
        refCol = sortBy(uniqBy(refCol, "time"), "time");
      } else {
        // It get refDataId from panel configuration or just the first one
        refDataId =
          cfgRefCol.data_id && cfgRefCol.data_id in history
            ? cfgRefCol.data_id
            : dataIds[0];
        refCol = history[refDataId]?.samples;
      }
      if (!refCol) {
        console.log("invalid configuration");
        return null;
      }

      const array = (lst, attr) => {
        return (lst || []).map((item) =>
          item.data_id &&
            item[attr] !== "" &&
            attr == "value" &&
            parsers[item.data_id]
            ? parsers[item.data_id](item[attr])
            : item[attr]
        );
      };

      let x = array(refCol, "time");

      return cols.map((id) => {
        if (sequenceColumn) {
          sequenceColumn = false; // turn off the sequence column
          return Array.from({ length: x.length }, (_, i) => i + 1);
        }
        if (timeColumn) {
          timeColumn = false; // turn off the time column
          return x;
        }
        if (refDataId && refDataId == id) {
          return array(refCol, "value");
        } else {
          let lst = id in history ? history[id]?.samples : [];
          if (lst.length) {
            switch (missingValues) {
              case "last_value":
                return array(fillList(refCol, lst, false), "value");
              case "leave_them_empty":
                return array(fillList(refCol, lst, true), "value");
              case "linear_interpolation":
                return linearInterpolation(
                  x,
                  array(lst, "time"),
                  array(lst, "value")
                );
              // any other function...
            }
          }
        }
      });
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    },
    dataIdList() {
      let lst = [];
      this.sheet.forEach((r) => {
        r.forEach((c) => {
          // let id = ((c?.data_id || "") + "").match(/\d+/g);
          let id = c?.data_id || "";
          if (id) {
            // lst.push(parseInt(id));
            lst.push(id);
          }
        });
      });
      return lst.filter((i, ix, a) => a.indexOf(i) == ix);
    },
    extendedDataList() {
      return this.$store.getters["dashboard/extendedDataList"] || [];
    },
    dataList() {
      return this.extendedDataList.filter(
        ({ id }) => this.dataIdList.indexOf(id) >= 0
      );
    },
    isSelected() {
      return (
        this.$parent.isSelected || this.$parent.$parent.isSelected || false
      ); // panel or synoptic control
    },
    isEditing() {
      // editing control is only TRUE after double clicking it.
      return this.$parent.isEditing || this.$parent.$parent.isEditing || false; // panel or synoptic control
    },
    historyInterval() {
      return this.$store.getters["history/interval"] || null;
    },
    mode() {
      return this.$store.getters["dashboard/mode"];
    },
    a1() {
      return this.address(this.active.r, this.active.c);
    },
    printPreview() {
      return this?.$store?.getters?.print || false;
    },
    pendingIds() {
      return (
        (this.datasetIdList.length && this.$store.getters["history/pending"]) ||
        []
      );
    },
    globalFunctions() {
      return this.$store.getters["scripts/globalFunctions"];
    }
  },
  watch: {
    size(n, o) {
      if (
        n &&
        n.cols &&
        n.rows &&
        (!o || o.cols != n.cols || o.rows != n.rows)
      ) {
        this.onSheetResize();
      }
    },
    historyInterval: {
      handler() {
        if (this.mode == "editor" || !this.standAlone) return; // it should make use of simulated samples only
        if (this.datasetIdList.length) {
          this.fetchDataSet();
        }
      },
      deep: true,
      immediate: true
    },
    isSelected(n) {
      if (n && this.standAlone) {
        this.$nextTick(() => {
          let entry = { action: "sheet:activate" };
          if (this.$el.clientHeight < this.$el.firstChild.clientHeight) {
            entry.minHeight = this.$el.firstChild.clientHeight;
          }
          if (this.$el.clientWidth < this.$el.firstChild.clientWidth) {
            entry.minWidth = this.$el.firstChild.clientWidth;
          }
          this.trigger(entry);
        });
      }
    },
    pendingIds(n, o) {
      if (o?.length && !n?.length) {
        this.validateDataSet();
      }
    }
  },
  methods: {
    isEnabled(r, c) {
      if (!this.isEditing || (this.datasetTarget && r > this.datasetTarget.r))
        return false;
      return true;
    },
    allowInput(r, c) {
      return (
        this.inlineEditor &&
        this.isEnabled(r, r) &&
        (!this.datasetTarget || r < this.datasetTarget.r) &&
        !this.labeledSheet.rows[r][c].data_id &&
        (!this.labeledSheet.rows[r][c].data_source ||
          this.labeledSheet.rows[r][c].data_source == "constant")
      );
    },
    isCellActive(r, c) {
      return r == this.active.r && c == this.active.c;
    },
    setCellValue(r, c, value) {
      this.trigger({
        action: "cell:set_value",
        details: {
          row: r,
          column: c,
          value: value
        }
      });
    },
    value(r, c) {
      let vlr = "";
      let cell = this.labeledSheet.rows[r][c];
      let data = this.dataAt(r, c);
      if (data) {
        if ("current_value" in data && data.current_value) {
          if (cell.format) {
            vlr = this.$utils.sprintf(cell.format, data.current_value.value);
          } else {
            vlr = data.current_value.value;
          }
        }
      } else {
        if (
          cell.value != undefined &&
          !((cell.value + "").indexOf("${") >= 0)
        ) {
          if (!isNaN(parseInt(cell.value)) && cell.format) {
            vlr = this.$utils.sprintf(cell.format, cell.value);
          } else {
            vlr = cell.value;
          }
        }
      }
      if (vlr === "" && cell.default !== "") {
        if (this.datasetTarget && r >= this.datasetTarget.r) {
          return vlr;
        }
        else {
          vlr = cell.default;
        }
      }
      return vlr;
    },
    getHistory(data_id) {
      let entries = this.$store.getters["history/entries"] || {};
      return (entries || {})[data_id];
    },
    dataAt(r, c) {
      let cell = this.labeledSheet.rows[r][c];
      let data = null;
      let value = cell.value;
      // indirect reference?
      // ros/cos = row and column offset
      let rc = null, ros = 0, cos = 0, vlr = "", indRef = `${value ?? ''}`.match(/R\[[-0-9]+\]C\[[-0-9]+\]/g);
      if (indRef) {
        indRef.forEach((ref) => {
          rc = ref.match(/[-\d]+/g);
          if (rc.length == 2) {
            ros = parseInt(r) + parseInt(rc[0]);
            cos = parseInt(c) + parseInt(rc[1]);
            if (isNaN(ros) || ros < 0 || ros > this.labeledSheet.rows.length - 1) return;
            if (isNaN(cos) || cos < 0 || cos > this.labeledSheet.rows[ros].length - 1) return;
            if (ros == r && cos == c) return;
            vlr = this.labeledSheet.rows[ros][cos].value;
            value = value.replace(ref, vlr);
          }
        });
      }
      if (cell.data_id) {
        if (this.datasetTarget && r >= this.datasetTarget.r) {
          return null;
        }
        data = this.dataList.find((i) => i.id == cell.data_id) || null;
        if (data) {
          data = JSON.parse(JSON.stringify(data));
          if (
            data?.device?.connector?.id > 0 &&
            !("description" in (data?.device?.connector || {}))
          ) {
            let connector = this.$store.getters["dashboard/connectorList"].find(
              ({ id }) => id == data.device.connector.id
            );
            if (connector) {
              data.device.connector = {
                ...data.device.connector,
                ...connector
              };
            }
          }
          data.history = this.getHistory(data.id);
          if (cell.format == "text_list") {
            if (cell?.stateList?.dataSource?.type != 'data' && cell?.stateList?.dataSource?.id != data?.text_list?.id) {
              data.text_list = {
                id: "",
                name: "",
                default_item: {},
                items: {}
              };
              // make it compatible with backend lists format
              (cell?.stateList?.items || []).forEach((item) => {
                data.text_list.items[item.state] = item.label;
              });
              if (
                cell?.stateList?.default !== "" &&
                cell?.stateList?.default !== undefined &&
                cell.stateList.default in data.text_list.items
              ) {
                data.text_list.default_item[cell.stateList.default] =
                  data.text_list.items[cell.stateList.default];
              }
            }
          } else {
            data.text_list = null; // important! otherwise it would handle if data also defines a text-list
            if (cell.default !== "") {
              data.default = cell.default;
            }
            currentValueTypeCast(data);
            if (value) {
              data.template = value;
            } else {
              data.template = "${data?.current_value?.value}";
            }
            data.template += cell.format ? "|" + cell.format : "";
          }
          return data;
        }
      } else if (cell.data_source == "system") {
        return {
          ...this.$store.getters.systemProperties,
          template: value + (cell.format ? "|" + cell.format : "")
        };
      } else if (this.datasetTarget && r >= this.datasetTarget.r) {
        if (cell.format == "text_list") {
          var refCell =
            this?.control?.synopticComponent?.sheet[this.datasetTarget.r][c];
          if (refCell) {
            data = {
              id: "",
              current_value: {
                value: value
              },
              text_list: {
                id: "",
                name: "",
                default_item: {},
                items: {}
              }
            };
            // make it compatible with backend lists format
            (refCell?.stateList?.items || []).forEach((item) => {
              data.text_list.items[item.state] = item.label;
            });
            if (
              refCell?.stateList?.default !== "" &&
              refCell?.stateList?.default !== undefined &&
              refCell.stateList.default in data.text_list.items
            ) {
              data.text_list.default_item[refCell.stateList.default] =
                data.text_list.items[refCell.stateList.default];
            }
            return data;
          } else {
            return {
              value: value,
              template: "${data.value}"
            };
          }
        }
        return {
          value: value,
          template: "${data.value}" + (cell.format ? "|" + cell.format : ""),
          default: cell.default
        };
      } else if (indRef || this.$root.$formatter.callsGlobal(value)) {
        return {
          template: value + (cell.format ? "|" + cell.format : ""),
          default: value
        };
      }
      return null;
    },
    cellState(r, c) {
      let cell = null;
      if (this.datasetTarget && r >= this.datasetTarget.r) {
        cell = this?.control?.synopticComponent?.sheet[this.datasetTarget.r][c];
      } else {
        cell = this.labeledSheet.rows[r][c];
      }
      if (cell && (cell?.stateList?.items || []).length) {
        return this.$root.$formatter.state(this.dataAt(r, c));
      }
      return null;
    },
    cellStyle(r, c) {
      let cell = this.labeledSheet.rows[r][c];
      if (cell) {
        let style = JSON.parse(JSON.stringify((cell && cell.style) || {}));
        if (cell?.textAlign) {
          style["text-align"] = cell.textAlign;
        } else {
          if (!("text-align" in style)) {
            style["text-align"] = "center";
          }
        }
        delete style["padding"]; // it is applied by the cell content method
        // apply any state css (not persistent css)
        let state = this.cellState(r, c) || null;
        if (state) {
          style["background-color"] = state.backgroundColor;
        }
        if ("white-space" in style && this.printPreview) {
          delete style["white-space"]; // treated by default printout cell class
        }
        return style;
      }
      return {};
    },
    cellContentStyle(r, c) {
      let cell = this.labeledSheet.rows[r][c];
      let style = {
        padding: cell?.style?.padding || "0"
      };
      return style;
    },
    cellClass(r, c) {
      if (this.isEditing) {
        if (this.active.r == r && this.active.c == c) {
          return "cell-edit active-cell";
        }
        return "cell-edit";
      }
      return "";
    },
    address(r, c) {
      let char = c <= 90 ? String.fromCharCode(c + 65) : "!"; // 90=Z and it is a too big table
      return `${char}${r + 1}`;
    },
    onMouseDown($event) {
      if (this.isEditing) {
        $event.stopPropagation();
      }
    },
    onCellClicked(r, c) {
      if (this.isEditing) {
        if (!this.isEnabled(r, c)) return;
        this.active.r = r;
        this.active.c = c;
        this.trigger({
          action: "cell:activate",
          details: {
            row: r,
            column: c
          }
        });
      }
    },
    onCellMenuClicked($event, ir, ic, option) {
      if (!this.isEditing) return;
      const r = ir !== null ? ir : this.active.r;
      const c = ic !== null ? ic : this.active.c;
      if (!option && !this.isEnabled(r, c)) return;
      if (option) {
        if (option == "cell:edit" && this.$refs.menu) {
          this.$refs.menu.close();
        }
        this.trigger({
          action: option,
          details: {
            row: this.active.r,
            column: this.active.c
          }
        });
      } else {
        this.onCellClicked(r, c);
        if (this.$refs.menu) {
          this.$refs.menu.close();
          this.menu = {
            sx: window.scrollX,
            sy: window.scrollY,
            oy:
              window.innerHeight - $event.pageY > 0
                ? 0
                : window.innerHeight - $event.pageY
          };
          let pos = {
            clientY: $event.clientY + window.scrollY,
            clientX: $event.clientX
          };
          this.$nextTick(() => {
            this.$refs.menu.open(pos);
          });
        }
      }
    },
    onContextMenu() {
      this.$root.$emit("controlSidebar:collapse", false);
      window.scrollTo(this.menu.sx, this.menu.sy);
    },
    onKeyUp($event) {
      if (!this.isEditing) return;
      let r = this.active.r;
      let c = this.active.c;
      let inp;
      if (!$event || $event.target.nodeName != "DIV") return;
      switch ($event.code) {
        case "ArrowDown":
          r += 1;
          if (r > this.sheet.length - 1) r = this.sheet.length - 1;
          break;
        case "ArrowUp":
          r -= 1;
          if (r < 0) r = 0;
          break;
        case "ArrowLeft":
          c -= 1;
          if (c < 0) c = 0;
          break;
        case "ArrowRight":
          c += 1;
          if (c > this.sheet[0].length - 1) c = this.sheet[0].length - 1;
          break;
        case "Enter":
        case "NumpadEnter":
          this.setInputFocus(r, c);
          return;
        case "Delete":
          inp = this.setInputFocus(r, c);
          if (inp) {
            this.setCellValue(r, c, "");
          }
          break;
        default:
          inp = this.setInputFocus(r, c);
          if (inp && $event.key && $event.key.length == 1) {
            inp.value += $event.key;
          }
      }
      this.onCellClicked(r, c);
    },
    setInputFocus(r, c) {
      if (this.$refs.sheet) {
        let els =
          this.$refs.sheet.children[r].children[c].getElementsByTagName(
            "INPUT"
          );
        if (els && els.length) {
          els[0].focus();
          return els[0];
        }
      }
      return null;
    },
    onMouseDownDragColumn(e, r, c) {
      e.stopPropagation();
      this.dragging.c = c;
      this.dragging.r = r;
      this.dragging.w = 0;
    },
    onMouseUpDragColumn(e, r, c) {
      e.stopPropagation();
      if (
        this.dragging.c == c &&
        this.dragging.r == r &&
        this.dragging.w == 0
      ) {
        this.dragging.c = -1;
        this.dragging.r = -1;
        this.dragging.w = -1;
      }
    },
    startDragColumn(e, r, c) {
      this.dragging.c = c;
      this.dragging.r = r;
      this.dragging.w = 0;
      e.dataTransfer.setDragImage(this.DragImage, 0, 0);
      // e.dataTransfer.setDragImage(new Image(), 0, 0);
      e.dataTransfer.effectAllowed = "move";
      this.eCel = e.target.parentNode;
      this.xIni = e.pageX || e.clientX;
      this.wIni = this.eCel.getBoundingClientRect().width;
      this.wPrv = undefined;
    },
    dragOverColumn(e) {
      e.preventDefault();
      e.stopPropagation();
      if (!this.eCel || !this.xIni || !this.dragging.c < 0) return;
      this.dragging.w = (e.pageX || e.clientX) - this.xIni;
    },
    stopDragColumn(e) {
      e.preventDefault();
      if (!this.eCel || !this.xIni || !this.dragging.c < 0) return;
      let total = this.eCel.parentNode.parentNode.getBoundingClientRect().width;
      let w = Math.round(((this.wIni + this.dragging.w) / total) * 100);
      if (w < 10) w = 10;
      if (this.wPrv != w) {
        this.wPrv = w;
        this.trigger({
          action: "column:set_width",
          details: {
            row: this.dragging.r,
            column: this.dragging.c,
            width: w
          }
        });
      }
      this.dragging.c = -1;
      this.dragging.r = -1;
      this.dragging.w = -1;
    },
    trigger(ev) {
      if (this.standAlone) {
        ev.details = ev.details || {};
        if (!ev.details.control) {
          ev.details.control = this.control.synopticComponent;
        }
        this.$root.$emit("table:event", ev);
      } else {
        this.$emit("tableEvent", ev);
      }
    },
    fetchDataSet() {
      if (this.datasetIdList.length) {
        this.$store.dispatch("history/fetch", this.datasetIdList); // default;
      }
    },
    onClick($event, opt) {
      if (this.isEditing && this.standAlone) {
        // Just active A0 cell, since it has already been activated by isSelected watcher
        let $tbl = document.getElementById(this.tableId);
        let x = 0, y = 0;
        if ($event && $tbl) {
          let $td = (document.elementsFromPoint($event.clientX, $event.clientY) || []).find((i) => i.nodeName == "TD");
          if ($td) {
            for (var i = 0, row; (row = ($tbl?.rows || [])[i]); i++) {
              for (var j = 0, td; (td = (row?.cells || [])[j]); j++) {
                if ($td == td) {
                  x = i; y = j;
                  break;
                }
              }
            }
          }
        }
        this.onCellClicked(x, y)
      }
    },
    onSheetResize() {
      this.$root.$emit("panel:resized");
      if (this.standAlone) {
        this.$nextTick(() => {
          let rect = this.tableRect();
          if (rect) {
            this.trigger({
              action: "sheet:client_rect",
              details: {
                width: rect.width,
                height: rect.height
              }
            });
          }
        });
      }
    },
    rectError() {
      if (this.mode != "editor") return false;
      let rect = this.tableRect();
      if (this.standAlone && rect) {
        return (
          this?.control?.synopticComponent?.clientRect?.width <
          Math.round(rect.width) ||
          this?.control?.synopticComponent?.clientRect?.height <
          Math.round(rect.height)
        );
      }
      return false;
    },
    normalizeRow(row, page) {
      // returns original header row if it's equivalent
      return row < this.datasetTarget?.r ? row : row + page.offset;
    },
    tableRect() {
      if (this?.$refs?.table?.length) {
        this.$refs.table[0].getBoundingClientRect();
      } else if (this?.$refs?.table) {
        this.$refs.table.getBoundingClientRect();
      } else {
        return null;
      }
    },
    onDownload(filetype) {
      if (!this.datasetTarget || !this.dataset) return;
      let fname = `dataset-${new Date()
        .toISOString()
        .replace(/[\-\:T]/g, "")
        .split(".")[0]
        .substr(2)}`;
      switch (filetype) {
        case "csv":
          this.$utils.HTMLTable2CSV(
            document.getElementById(this.tableId),
            fname,
            () => {
              this.$emit("done");
            },
            false
          );
          break;
        case "xls":
          this.$utils.HTMLTable2XLS(
            document.getElementById(this.tableId),
            fname,
            () => {
              this.$emit("done");
            },
            false
          );
          break;
      }
    },
    async buildValues() {
      // keep in mind this?.control?.synopticComponent? will be soon synopticComponent properties
      // console.time(`buildValues${this._uid}`);
      this.datasetReady = false;
      this._values = null;
      let values = this?.control?.synopticComponent?.sheet || null;
      if (!values || !values.length || !values[0].length) {
        // console.timeEnd(`buildValues${this._uid}`);
        return;
      }
      const nColumns = values[0].length;

      // dataset configuration
      const target = this.datasetTarget;
      if (!this.dataset || !target) {
        this._values = values;
        // console.timeEnd(`buildValues${this._uid}`);
        return;
      }

      // build a combined values
      values = JSON.parse(JSON.stringify(values));

      // freeze cell references - since it will be merged with this.dataset
      let refRow = values[target.r].map((col) =>
        JSON.parse(JSON.stringify(col))
      );

      // merge this.dataset (array) with values
      let cell = null;
      for (var c = 0; c < nColumns; c++) {
        let hasHistory = false;
        let col = c - target.c;
        if (col >= 0 && col < this.dataset.length) {
          hasHistory = (this.dataset || [])[col] ? true : false;
        }
        for (var r = target.r; r < target.r + this.dataset[0].length; r++) {
          let row = r - target.r;
          cell = defCell();
          cell.value = hasHistory ? this.dataset[col][row] : "";
          cell.style = refRow[c].style;
          cell.format = refRow[c].format;
          cell.default = refRow[c].default;
          values[r] = values[r] || [];
          values[r][c] = cell;
        }
      }
      this._values = values;
      this.datasetReady = true;

    },
    validateDataSet() {
      if (!this?.datasetIdList?.length || this?.pendingIds?.length) return;
      const status = this.$store.getters["history/ready"];
      if (this.datasetIdList.some((id) => status[id])) {
        this.buildValues();
      }
    }
  },
  mounted() {
    this.tableId = `${this.$options.name}${this._uid}`;
    this.$emit("tableId", this.tableId);
    this.$emit("hasContent", true);
    this.$nextTick(() => {
      this.errorCheck += 1;
      this.validateDataSet();
    });
    if (this.pageHeight) {
      this.rowHeight =
        this.$refs.table[0].querySelector(
          `tr:nth-child(${(this.datasetTarget?.r ?? 0) + 1})`
        ).clientHeight * (this.printScale ?? 1);
    }
  },
  beforeCreate() {
    this.DragImage = new Image();
    this.DragImage.src =
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2\
ZyB3aWR0aD0iMSIgaGVpZ2h0PSIxIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxIDEiIHht\
bG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3Lncz\
Lm9yZy8yMDAwL3N2ZyI+PC9zdmc+Cg==";
  },
  created() {
    this.eCel = undefined; // not reactive
    this.xIni = undefined; // not reactive
    this.wIni = undefined; // not reactive
    this.wPrv = undefined; // not reactive
    if (this.datasetTarget && (this.datasetIdList || []).length) {
      this.$root.$on("toolbar:download", this.onDownload);
    }
  },
  beforeDestroy() {
    this.DragImage = null;
    this.$root.$off("toolbar:download", this.onDownload);
  }
};
</script>

<style scoped>
.me {
  width: 100%;
  vertical-align: middle;
  height: 100%;
}

tr,
th,
td {
  page-break-inside: avoid;
}

.display-table {
  table-layout: fixed;
  margin: 0;
  height: 100%;
  width: 100%;
  -webkit-text-size-adjust: var(--text-size-adjust);
  -moz-text-size-adjust: var(--text-size-adjust);
  text-size-adjust: var(--text-size-adjust);
}

.display-table > thead > tr,
.display-table > tbody > tr,
.display-table > tfoot > tr,
.display-table > thead > tr,
.display-table > tbody > tr,
.display-table > tfoot > tr {
  height: 22px;
}

.display-table > thead > tr > th,
.display-table > tbody > tr > th,
.display-table > tfoot > tr > th,
.display-table > thead > tr > td,
.display-table > tbody > tr > td,
.display-table > tfoot > tr > td {
  position: relative;
  margin: 0;
  border-color: var(--border-color);
  border-width: var(--border-width);
  border-style: var(--border-style);
  overflow: hidden;
}

.display-table > tbody > tr > td {
  transition: background-color 0.5s ease;
  vertical-align: middle;
}

.table-error > thead > tr > th,
.table-error > tbody > tr > th,
.table-error > tfoot > tr > th,
.table-error > thead > tr > td,
.table-error > tbody > tr > td,
.table-error > tfoot > tr > td {
  background-color: #ff000008 !important;
  transition: none;
}

.print-preview > thead > tr > th,
.print-preview > tbody > tr > th,
.print-preview > tfoot > tr > th,
.print-preview > thead > tr > td,
.print-preview > tbody > tr > td,
.print-preview > tfoot > tr > td {
  white-space: nowrap;
  overflow: hidden;
}

.cell {
  border: none;
  outline: none;
}

.cell:focus {
  border: none;
  outline: none;
}

/* edit mode */
.cell-edit {
  outline-offset: -2px;
  outline: 1px groove transparent;
}

.cell-edit:hover {
  /* cursor: pointer;
  opacity: 0.8; */
}

.active-cell,
.active-cell:hover {
  outline-color: rgb(78, 145, 221);
  /* border-color: transparent !important; */
  /* z-index: 1; */
}

.cell-separator {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 4px;
  right: 0;
  border-width: 0 1px 0 1px;
  border-style: none;
  border-color: darkgray;
  border-radius: 3px;
  cursor: col-resize;
  z-index: 1;
}

.cell-edit:hover > .cell-separator,
.cell-edit > .cell-separator.column-dragging {
  border-style: double;
  /* z-index: 3; */
}

.contextmenu {
  position: absolute;
  max-height: initial;
  padding: 10px 15px;
  border: 1px solid #999;
  border-radius: 0.4rem;
  font-size: 14px;
  text-align: left;
  background-color: white;
  z-index: 100;
}

.contextmenu > li {
  cursor: pointer;
  list-style: none;
}

.contextmenu > li:hover {
  background-color: #aaa;
}

.contextmenu > li.divider {
  margin: 12px -15px;
  height: 1px;
  border-top: 1px solid #aaa;
}

.contextmenu > li > a {
  color: #333;
  text-align: left;
  padding: 2px;
  font-size: 1.2rem;
}

.contextmenu > li > a:hover {
  cursor: pointer;
  opacity: 0.8;
}

.contextmenu > li > a > i {
  font-size: 1.168em;
  margin-right: 1rem;
  margin-left: 0rem;
}

.contextmenu > li.contextmenu-info {
  position: absolute;
  top: 0;
  background-color: transparent;
  color: #000;
  z-index: 101;
  font-size: 75%;
  padding: 2px 4px;
}

.contextmenu > li.contextmenu-info:hover {
  background-color: transparent !important;
  opacity: 0.8;
  color: #a95c44;
}

.contextmenu > li.address {
  left: 2px;
  color: #a95c44;
}

.contextmenu > li.close {
  right: 0px;
  top: 2px;
}

.contextmenu > li.gap {
  margin: 8px -15px 2px -15px;
  height: 0;
  padding: 0;
  border-bottom: 1px solid rgb(206, 206, 206);
}

.hide {
  background-color: red;
}
</style>

<style>
.page-break {
  page-break-before: always;
}
</style>
